// React & Next
import { useState } from 'react';

// 3rd
import { Divider, Flex, ModalCloseButton } from '@chakra-ui/react';

// App - Other
import Locale from '@/locale/en.json';
import { Heading, Text } from '@/components/atoms/typography';
import { Button } from '@/components/molecules/button';
import { UploadField } from '@/components/molecules/form';
import { Modal } from '@/components/molecules/modal';

const locale = Locale.features.frameworks['create-framework-modal'];

type CreateFrameworkModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateFrameworkModal = ({ isOpen, onClose }: CreateFrameworkModalProps) => {
  const [isLoadingBlank, setIsLoadingBlank] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [frameworkFile, setFile] = useState<File>();

  const handleCloseModal = () => {
    onClose();
    setFile(undefined);
    setIsLoadingBlank(false);
    setIsLoadingFile(false);
  };

  const onSubmitBlank = () => {
    setIsLoadingBlank(true);

    setTimeout(() => {
      setIsLoadingBlank(false);
      handleCloseModal();
    }, 2000);
  };

  const onSubmitFile = () => {
    setIsLoadingFile(true);

    setTimeout(() => {
      setIsLoadingFile(false);
      handleCloseModal();
    }, 2000);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <ModalCloseButton top="24px" right="24px" bg="transparent" />

        <Modal.Header
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Heading>{locale['Create framework']}</Heading>
        </Modal.Header>

        <Modal.Body w="100%">
          <Flex direction="column" gap="24px" w="100%">
            <UploadField onUpload={(files) => setFile(files)} />

            <Button
              variant="old.solid"
              aria-label={locale['Upload framework configuration file']}
              alignSelf="flex-end"
              isDisabled={!frameworkFile}
              isLoading={isLoadingFile}
              onClick={() => onSubmitFile()}
            >
              {locale['Next']}
            </Button>
          </Flex>

          <Flex alignItems="center" py="32px">
            <Divider />

            <Text variant="detail" px="16px" color="#bdbdbd">
              {locale['OR']}
            </Text>

            <Divider />
          </Flex>

          <Flex direction="column" alignItems="center" w="100%">
            <Button
              variant="old.solid"
              size="md"
              aria-label={locale['Create new blank framework']}
              isDisabled={!!frameworkFile}
              isLoading={isLoadingBlank}
              onClick={() => onSubmitBlank()}
              w="120px"
              h="40px"
            >
              {locale['Create blank']}
            </Button>
          </Flex>
        </Modal.Body>
      </Modal>
    </>
  );
};
