// React & Next
import type { ReactNode } from 'react';

// 3rd
import {
  Modal as ChakraModal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import type { BoxProps, ModalHeaderProps, ModalBodyProps } from '@chakra-ui/react';

type WizardProps = {
  isOpen: boolean;
  onClose: () => void;
  closeOnEsc?: boolean;
  closeOnOverlayClick?: boolean;
  size?: string;
  children: ReactNode;
};

export const Modal = ({
  isOpen,
  onClose,
  closeOnEsc = true,
  closeOnOverlayClick = true,
  children,
  size,
}: WizardProps) => {
  // TODO not sure if I want to pass onClose from outside
  return (
    <ChakraModal
      isOpen={isOpen}
      isCentered={true}
      closeOnEsc={closeOnEsc}
      closeOnOverlayClick={closeOnOverlayClick}
      blockScrollOnMount={true} // Prevent user from background scrolling when modal is open
      preserveScrollBarGap={true} // Prevents flickering when modal opens
      returnFocusOnClose={false} // No need as usually a button will trigger the modal, so we don't want to return the focus to it
      trapFocus={false}
      scrollBehavior="inside" // Only modal body is scrollable
      size={size || 'lg'} // TODO Need to think about it
      onClose={onClose}
      onEsc={onClose}
      onOverlayClick={onClose}
    >
      <ModalOverlay />

      <ModalContent p="32px">{children}</ModalContent>
    </ChakraModal>
  );
};

const Header = ({ children, ...rest }: { children: ReactNode } & Partial<ModalHeaderProps>) => {
  return (
    <ModalHeader p={0} mb="32px" {...rest}>
      {children}
    </ModalHeader>
  );
};

Modal.Header = Header;

const Body = ({ children, ...rest }: { children: ReactNode } & Partial<ModalBodyProps>) => {
  return (
    <ModalBody p={0} {...rest}>
      {children}
    </ModalBody>
  );
};

Modal.Body = Body;

const Footer = ({ children, ...boxProps }: { children: ReactNode } & Partial<BoxProps>) => {
  return (
    <ModalFooter mt="32px" p={0} {...boxProps}>
      {children}
    </ModalFooter>
  );
};

Modal.Footer = Footer;
